<template>
  <div class="content">
    <el-row>
      <el-col :span="24" xs="24">
        <div class="ipt">
          <el-input
            size="mini"
            clearable
            placeholder="请输入起始城市"
            v-model="vm.search.StartSiteName"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"
              >查询</el-button
            >
          </el-input>
        </div>
        <div v-if="routeData != ''" class="table">
          <el-table
            :data="routeData"
            :border="true"
            :stripe="true"
            :size="'mini'"
            :empty-text="'无数据'"
          >
            <el-table-column align="center" prop="routeName" label="路线名称">
            </el-table-column>
            <el-table-column
              align="center"
              prop="startSiteName"
              label="起始城市"
            >
            </el-table-column>
            <el-table-column align="center" prop="endSiteName" label="终点城市">
            </el-table-column>
            <el-table-column
              prop="pickUpAddress"
              label="提货地址"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pickUpPhone" label="提货电话" align="center">
            </el-table-column>
            <el-table-column prop="startTime" label="发车时间" align="center">
            </el-table-column>
            <el-table-column
              align="center"
              prop="telephoneNumber"
              label="投诉电话"
            >
            </el-table-column>
          </el-table>
          <el-pagination
            background
            style="margin-top: 10px"
            @current-change="onPageChange"
            @size-change="onPageSizeChange"
            :current-page="vm.pageIndex"
            :page-size="vm.pageSize"
            layout="prev, pager, next"
            :total="vm.total"
          >
          </el-pagination></div
      ></el-col>
    </el-row>
  </div>
</template>
<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";

export default {
  name: "waybill-search",
  data() {
    return {
      vm: {
        search: {
          StartSiteName: "",
        },
        pageIndex: 1,
        pageSize: 10,
        noCount: false,
      },
      routeData: "",
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      let url = `${BASE_URL}/api/home/PagingRoute`;
      this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: this.vm,
      }).then((res) => {
        this.routeData = res.data.content.rows;
        this.vm.total = res.data.content.total;
      });
    },
    onPageChange: function(pageIndex) {
      this.vm.pageIndex = pageIndex;
      this.search();
    },
    onPageSizeChange: function(pageSize) {
      this.vm.pageSize = pageSize;
      this.search();
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  width: 100%;
}
.ipt {
  text-align: left;
  width: 300px;
}
.titleItem {
  padding-left: 0px !important;
}
.el-pagination {
  text-align: right;
}
.table {
  margin-top: 20px;
}
</style>
